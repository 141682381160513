<template>
  <a-modal
    centered
    title="新建定时任务"
    width="33%"
    :visible="true"
    :maskClosable="false"
    @ok="onOk"
    @cancel="$emit('close')"
  >
    <div class="add-modal-container">
      <a-form-model
        ref="refForm"
        :model="form"
        :rules="rules"
        layout="vertical"
      >
        <!-- 开始时间 -->
        <a-form-model-item label="开始时间:" prop="startTime">
          <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            v-model="form.startTime"
            show-time
          />
        </a-form-model-item>

        <!-- 结束时间 -->
        <a-form-model-item label="结束时间:" prop="endTime">
          <a-radio-group v-model="endTimeType" @change="endTimeTypeChange">
            <a-radio :value="0">
              时长
            </a-radio>
            <a-radio :value="1">
              停止时间
            </a-radio>
          </a-radio-group>
          <div style="padding-top: 8px;">
            <template v-if="endTimeType === 0">
              <div class="hours-mins-secs">
                <a-input-number v-model="hours" :max="2" :min="0" /><span
                  >时</span
                >
                <a-input-number v-model="mins" :max="59" :min="0" /><span
                  >分</span
                >
                <a-input-number v-model="secs" :max="59" :min="0" /><span
                  >秒</span
                >
              </div>
            </template>
            <template v-else>
              <a-date-picker
                format="YYYY-MM-DD HH:mm:ss"
                v-model="form.endTime"
                show-time
                @change="endTimeChange"
              />
            </template>
          </div>
        </a-form-model-item>

        <!-- 录制格式 -->
        <a-form-model-item label="录制格式:" prop="recordType">
          <a-select
            placeholder="请选择录制格式"
            v-model="form.recordType"
            allowClear
          >
            <a-select-option
              v-for="item in recordTypes"
              :key="item.key"
              :value="item.value"
            >
              {{ item.key }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <!-- 保存位置 -->
        <a-form-model-item label="保存位置:" prop="memoryAddress">
          <a-select
            placeholder="请选择保存位置"
            v-model="form.memoryAddress"
            allowClear
          >
            <a-select-option
              v-for="item in memoryAddressOpts"
              :key="item.key"
              :value="item.value"
            >
              {{ item.key }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <!-- 任务名称 -->
        <a-form-model-item label="任务名称:" prop="taskName">
          <a-input
            placeholder="请输入任务名称"
            v-model="form.taskName"
            allowClear
          />
        </a-form-model-item>

        <!-- 摄像头 -->
        <a-form-model-item label="摄像头:">
          <a-button type="primary" @click="cameraSelectShow = true"
            >+选择摄像机</a-button
          >
        </a-form-model-item>
      </a-form-model>

      <!-- 摄像机列表 -->
      <ul class="cameras">
        <li v-for="(camera, index) of selectCameraList" :key="index">
          <span class="ellipsis">{{ camera.cameraName }}</span>
          <a-icon
            title="删除"
            type="delete"
            class="btn"
            @click="delCamera(camera.cameraId)"
          />
        </li>
      </ul>
    </div>

    <!-- 选择摄像机弹窗 -->
    <cameral-select
      :visible="cameraSelectShow"
      @closeCameralDialog="cameraSelectShow = false"
    />
  </a-modal>
</template>

<script>
import cameralSelect from '@/components/CameralSelect';

export default {
  name: '',
  components: {
    cameralSelect,
  },
  props: {},
  data() {
    const recordTypes = [
      {
        key: 'MP4',
        value: 'MP4',
      },
      {
        key: 'FLV',
        value: 'FLV',
      },
    ];
    const memoryAddressOpts = [
      {
        key: '云端存储',
        value: 1,
      },
      {
        key: '本地存储',
        value: 2,
      },
    ];
    const rules = {
      startTime: [
        {
          required: true,
          message: '请选择开始时间',
          trigger: 'blur',
        },
      ],
      endTime: [
        {
          required: false,
          message: '请设置时长或选择结束时间',
          trigger: 'blur',
        },
      ],
      recordType: [
        {
          required: true,
          message: '请选择录制格式',
          trigger: 'blur',
        },
      ],
      memoryAddress: [
        {
          required: true,
          message: '请选择存储位置',
          trigger: 'blur',
        },
      ],
      taskName: [
        {
          required: true,
          message: '请输入任务名称',
          trigger: 'blur',
        },
        {
          max: 20,
          message: '最大20个字符',
          trigger: 'blur',
        },
      ],
    };
    return {
      form: {
        startTime: '',
        endTime: '',
        recordType: 'MP4',
        memoryAddress: 2,
        taskName: '',
      },
      rules,
      endTimeType: 0,
      hours: '1',
      mins: '0',
      secs: '0',
      recordTypes, // 录制格式
      memoryAddressOpts, // 存储位置
      cameraSelectShow: false, // 选择摄像机弹窗显隐
      endTimeBack: '', // 切换时长日期保存end
    };
  },

  computed: {
    duration() {
      return (
        (Number(this.hours) * 3600 +
          Number(this.mins) * 60 +
          Number(this.secs)) *
        1000
      ); // ms
    },

    endTimeRequired() {
      const hasDuration =
        Number(this.hours) > 0 ||
        Number(this.mins) > 0 ||
        Number(this.secs) > 0;
      return this.endTimeType === 0 && hasDuration;
    },

    selectCameraList: (vm) => vm.$store.state.collect.selectCameraList,
  },

  watch: {
    endTimeRequired(v) {
      this.rules.endTime[0].required = !v;
    },
  },

  methods: {
    onOk() {
      // 任务名称 必填，缺省为“新建录像”, max20个字符
      !this.form.taskName && (this.form.taskName = '新建录像');

      this.$refs.refForm.validate((valid) => {
        if (valid) {
          if (this.selectCameraList.length <= 0) {
            this.$message.warning('请先选择摄像机');
            return;
          }
          const params = {
            startTime: this.form.startTime._d?.getTime?.(),
            endTime: this.form.endTime._d?.getTime?.(),
            videoType: this.form.recordType,
            storageMode: this.form.memoryAddress,
            taskName: this.form.taskName,
            cameraId: this.selectCameraList.map((e) => e.cameraId),
          };

          if (this.endTimeType === 0) {
            // 计算结束时间点
            params.endTime = params.startTime + this.duration;
          }
          if (params.startTime <= +new Date()) {
            this.$message.warning('开始时间应该在当前时间之后');
            return;
          }
          if (params.endTime <= params.startTime) {
            this.$message.warning('结束时间应该大于开始时间');
            return;
          }
          if (params.endTime - params.startTime > 3600 * 1000 * 2) {
            // 最大时长2h
            this.$message.warning('最大时长2小时');
            return;
          }

          params.startTime += '';
          params.endTime += ''; // 时间转字符串

          this.$api.timingRecording.handle(params).then((res) => {
            if (res.code === 200) {
              this.$message.success('新建成功');
              this.$emit('updateTableData');
              this.$emit('close');
            }
          });
        }
      });
    },

    // 删除所选摄像机
    delCamera(cameraId) {
      const newList = this.selectCameraList.map((e) => e);
      newList.splice(
        newList.findIndex((e) => e.cameraId === cameraId),
        1
      );
      this.$store.commit('setSelectCameraList', newList);
    },

    endTimeTypeChange(e) {
      if (e.target.value === 0) {
        this.form.endTime = '';
      } else {
        this.form.endTime = this.endTimeBack;
      }
    },

    endTimeChange(time) {
      this.endTimeBack = time;
    },
  },

  mounted() {},
};
</script>

<style scoped lang="less">
.add-modal-container {
  max-height: calc(100vh - 200px);
  overflow-y: overlay;
  padding: 0 6px;

  .ant-form {
    .ant-form-item {
      margin-bottom: 5px;

      .ant-form-item-control-wrapper {
        .ant-calendar-picker {
          width: 100%;
        }

        .hours-mins-secs {
          .ant-input-number {
            & + span {
              color: #86b3e9;
              margin: 0 5px;
            }
          }
        }

        .ant-btn {
          width: 100%;
        }
      }
    }
  }

  ul.cameras {
    li {
      align-items: center;
      display: flex;
      justify-content: space-between;

      span {
        max-width: 90%;
      }
    }
  }
}
</style>
