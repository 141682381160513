export default class MyTable {
  constructor(vm, options = {
    api: '',
    columns: [],
    handleData: () => {},
    loading: true,
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [],
    showTotal: () => {}
  }) {
    this.api = options.api || ''
    this.columns = options.columns ?? []
    this.loading = options.loading ?? true
    const pageOrSizeChange = (current, pageSize) => {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getTableData()
    }
    this.pagination = options.pagination || {
      current: 1,
      defaultCurrent: 1,
      defaultPageSize: 10,
      pageSize: 10,
      pageSizeOptions: ['5', '10', '15', '20'],
      showSizeChanger: true,
      showQuickJumper: true,
      showTotal: options.showTotal || (total => `总计${total}条`),
      total: 0,
      onChange: pageOrSizeChange,
      onShowSizeChange: pageOrSizeChange
    }

    this.getTableData = function() {
      try {
        this.loading = true
        this.api.split('.').reduce((acc, e) => acc[e], this.$api)({
          ...this.apiParams,
          currPage: this.pagination.current,
          pageSize: this.pagination.pageSize
        }).then(res => {
          options.handleData?.(res)
          this.tableData = res.data
          this.pagination.total = res.total
          this.loading = false
        })
      } catch {
        throw new Error('请传入正确的option.api接口字符串以匹配$api的指定方法')
      }
    }.bind(vm)

    this.tableData = []
  }
}

/* 
1.
import MyTable from '@assets/scripts/utils/myTable'; // 引入


2.
data() {
  return {
    ...new MyTable(this, { api: 'aiCenterS.getBehavior' }) // 输入api必要参数使用
  }
}
或者先详细自定义设置
data() {
  const columns = [
    {...},
    {...}
  ]
  const options = {
    api: 'aiCenterS.getBehavior',
    columns,
    handleData: res => res.data.map(e => e.a = e.b + 1),
    defaultPageSize: 3,
    pageSizeOptions: ['3', '6', '9']
  }
  return {
    ...new MyTable(this, options), // 输入api必要参数使用
    // columns, // columns可以传入options亦可直接在data中重定义
  }
}

3.
computed: {
  apiParams() { // 设置计算属性apiParams会自动传入getTableData请求中作为参数(仅数据参,无需传入页码参)
    return {
      p1: this.formData.pa,
      p2: this.formData.pb
    }
  }
}
*/