<template>
  <div class="timing-recording-containter">
    <back class="m-b-sm" url="/home" />
    <tab :tabs="tabs" class="m-b" v-show="false" />

    <!-- 操作条 -->
    <div class="table-search-actions-bar">
      <div class="search-bar-wrapper flex flex-wrap justify-between">
        <div class="conditions flex-one">
          <!-- 选择省份 -->
          <div class="label">
            <span>省份：</span>
            <a-select
              placeholder="请选择省份"
              v-model="conditions.province"
              allowClear
            >
              <a-select-option
                v-for="item in provinces"
                :key="item.regionCode"
                :value="item.regionCode"
              >
                {{ item.alias }}
              </a-select-option>
            </a-select>
          </div>

          <!-- 管辖单位 -->
          <div class="label">
            <span>管辖单位：</span>
            <a-cascader
              :options="units"
              v-model="conditions.organizationIds"
              expand-trigger="click"
              placeholder="请选择管辖单位"
              change-on-select
              :display-render="({ labels }) => labels.slice(-1)"
            />
          </div>

          <!-- 摄像机名称 -->
          <div class="label">
            <span>摄像机名称：</span>
            <a-input
              placeholder="请输入摄像机名称"
              v-model="conditions.camcorderName"
              allowClear
            />
          </div>

          <!-- 时间 -->
          <div class="label">
            <span>起止时间：</span>
            <a-range-picker
              v-model="conditions.timeRange"
              :show-time="{ format: 'HH:mm:ss' }"
            />
          </div>

          <div class="search-btns-wrapper">
            <a-button
              type="primary"
              class="m-r-sm btn-search"
              @click="getTableData"
            >
              搜索
            </a-button>
            <a-button class="btn-reset" @click="rest"> 重置 </a-button>
          </div>
        </div>
        <div class="flex">
           <span class="span-line m-l-md m-r-md"></span>
          <a-button class="btn-export" @click="openAddModal">定时录制</a-button>
        </div> 
      </div>
      
    </div>

    <!-- 表格 -->
    <a-table
      :loading="loading"
      :columns="columns"
      :components="resibleTableHeader"
      :rowKey="(record, index) => index"
      bordered
      :data-source="tableData"
      :pagination="pagination"
      :scroll="scroll"
    >
      <template slot="action" slot-scope="row">
        <a-icon
          type="edit"
          title="修改"
          class="btn"
          v-if="row.taskState === 0"
          @click="editRow(row)"
        />
        <a-icon
          type="play-circle"
          title="查看"
          class="btn"
          v-else-if="row.taskState === 3"
          @click="playRecording(row)"
        />
        <a-icon type="edit" title="修改" class="no-edit btn" v-else />
        <a-icon
          type="delete"
          title="删除"
          class="btn"
          @click="delRow(row.taskName, row.id)"
        />
      </template>
    </a-table>

    <!-- 新建定时录制弹窗 -->
    <add-modal
      v-if="isAddModalShow"
      @close="closeHandle"
      @updateTableData="getTableData"
    />

    <!-- 修改定时录制弹窗 -->
    <edit-modal
      v-if="isEditModalShow"
      :rowData="editRowData"
      @close="isEditModalShow = false"
      @updateTableData="getTableData"
    />

    <!-- 视频播放弹窗 -->
    <videoPlayer
      :visible="isVideoPlayerShow"
      :record="playRowData"
      @closeVideo="isVideoPlayerShow = false"
    />
  </div>
</template>

<script>
// import Back from '../../components/Back.vue';
import Tab from '@/components/Tab';
import { mapActions } from 'vuex';
import tabMixin from '@/components/mixins/tabs';
import MyTable from '@assets/scripts/utils/myTable';
import addModal from './AddModal';
import editModal from './EditModal';
import videoPlayer from '@components/VideoPlayerDialog';
import moment from 'moment';
import Back from '@/components/Back';
import ResizableTable from '@/components/mixins/resizeableTable';
import { mapMutations } from 'vuex';
export default {
  name: 'TimingRecording',
  mixins: [tabMixin,ResizableTable],
  components: {
    Back,
    Tab,
    addModal, // 新建定时录制弹窗
    editModal, // 修改定时录制弹窗
    videoPlayer, // 视频播放
  },
  data() {
    const tabs = [
      {
        name: '定时录像',
      },
    ];

    const columns = [
      {
        title: '排序',
        dataIndex: 'taskIndex',
        customRender: (text, record, index) => index + 1,
        width: 60,
      },
      {
        title: '任务名称',
        dataIndex: 'taskName',
        ellipsis: true,
        width:120,
      },
      {
        title: '区域',
        dataIndex: 'regionName',
        ellipsis: true,
        width:120
      },
      {
        title: '管辖单位',
        dataIndex: 'organizationName',
        ellipsis: true,
        width:120
      },
      {
        title: '所属路线',
        dataIndex: 'roadName',
        ellipsis: true,
        width:120
      },
      {
        title: '摄像机名称',
        dataIndex: 'cameraName',
        ellipsis: true,
        width:180
      },
      {
        title: '桩号',
        dataIndex: 'kmHmPile',
        ellipsis: true,
        width:100
      },
      {
        title: '开始时间',
        dataIndex: 'startTime',
        ellipsis: true,
        width: 150,
      },
      {
        title: '结束时间',
        dataIndex: 'endTime',
        ellipsis: true,
        width: 150,
      },
      {
        title: '录制格式',
        dataIndex: 'videoType',
        ellipsis: true,
        width: 80,
      },
      {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        ellipsis: true,
        width: 150,
      },
      {
        title: '存储方式',
        dataIndex: 'storageMode',
        ellipsis: true,
        customRender: (value) =>
          ({
            1: '云端存储',
            2: '本地存储',
          }[value]),
        width: 80,
      },
      {
        title: '录制状态',
        dataIndex: 'taskState',
        ellipsis: true,
        customRender: (value) =>
          ({
            '-1': '录制失败',
            0: '未开始',
            1: '录制中',
            2: '合成中',
            3: '已完成',
          }[value]),
        width: 80,
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        width: 70,
      },
    ];

    return {
      ...new MyTable(this, { api: 'timingRecording.getTableData' }),
      columns,
      scroll: { y: window.innerHeight - 338,x:'100%' },
      tabs,
      conditions: {
        // 搜索条件数据
        province: undefined,
        organizationIds: [],
        camcorderName: '',
        timeRange: [],
      },
      isAddModalShow: false, // 新建定时录制弹窗显隐
      isEditModalShow: false, // 修改定时录制弹窗显隐
      editRowData: {},
      isVideoPlayerShow: false, // 视频播放显隐
      playRowData: [{}],
    };
  },
  computed: {
    apiParams: (vm) => ({
      startTime:
        vm.conditions.timeRange[0] &&
        moment(vm.conditions.timeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
      endTime:
        vm.conditions.timeRange[1] &&
        moment(vm.conditions.timeRange[1]).format('YYYY-MM-DD HH:mm:ss'),
      cameraName: vm.conditions.camcorderName,
      regionCode: vm.conditions.province,
      organizationId: vm.conditions.organizationIds.slice(-1)[0],
    }), // 自动传入请求的参数apiParams
    provinces: (vm) => vm.$store.state.collect.provinceList, // 省份列表
    units: (vm) => vm.$store.state.collect.organizationList, // 所属单位可选列表
  },
  mounted() {
    this.getTableData();
    this.getProvince({ regionLevel: 1 });
    this.getOrganationList();
  },
  methods: {
    ...mapActions(['getProvince', 'getOrganationList']),
    ...mapMutations(['setSelectCameraList']),
    closeHandle() {
      this.isAddModalShow = false;
      this.setSelectCameraList([]);
    },
    rest() {
      this.conditions = {
        // 搜索条件数据
        province: undefined,
        organizationIds: [],
        camcorderName: '',
        timeRange: [],
      };
      this.getTableData();
    },

    openAddModal() {
      if (!this.hasPermission(109210111210)) {
        this.permissionWarning();
        return;
      }

      this.isAddModalShow = true;
    },

    playRecording(row) {
      const data = JSON.parse(JSON.stringify(row));
      data.videoTapeName = `${data.cameraName}-${data.kmHmPile}`;
      this.playRowData = [data];
      this.isVideoPlayerShow = true;
    },

    editRow(row) {
      if (!this.hasPermission(109210111211)) {
        this.permissionWarning();
        return;
      }

      this.editRowData = row;
      this.isEditModalShow = true;
    },

    delRow(description, rowId) {
      if (!this.hasPermission(109210111212)) {
        this.permissionWarning();
        return;
      }

      const _this = this;
      this.$confirm({
        title: '提醒',
        content: `确认删除 "${description}" 录像任务吗？`,
        onOk() {
          console.log('del rowId', rowId);
          _this.$api.timingRecording
            .handle({
              id: rowId,
              status: '0',
            })
            .then((res) => {
              _this.$message[res.code === 200 ? 'success' : 'warning'](
                res.message || '删除成功'
              );
              res.code === 200 && _this.getTableData();
            });
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.timing-recording-containter {
  background-color: @bgColor;
  height: 100%;
  padding: @gapSize;

  ::v-deep .ant-table-wrapper {
    .ant-table-header {
      .ant-table-thead {
        tr {
          th {
            padding: 16px 2px;
            text-align: center;
          }
        }
      }
    }
    .ant-table-body {
      .ant-table-thead {
        tr {
          th {
            padding: 16px 2px;
            text-align: center;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            padding: 16px 2px;
            text-align: center;

            .btn {
              margin-right: @gapSize;
              &:last-child {
                margin-right: 0;
              }
              &.no-edit {
                color: #aaa;
                cursor: not-allowed;
              }
            }

            // .backward-btn {
            //   background: url(~@assets/home/backward.png) 0 0 / contain
            //     no-repeat;
            //   cursor: pointer;
            //   display: block;
            //   height: 18px;
            //   margin: 0 auto;
            //   width: 18px;
            //   &.disabled {
            //     cursor: not-allowed;
            //     filter: grayscale(1);
            //   }
            // }
          }
        }
      }
    }
  }
}
</style>
